<template>
  <div id="user-login">
    <h2>用户注册</h2>
    <a-form
      :model="form"
      :style="{ width: '600px' }"
      @submit="loginSubmit"
      label-align="left"
      auto-label-width
    >
      <a-form-item
        field="name"
        tooltip="用户名、手机号或者电子邮箱"
        label="用户名"
      >
        <a-input v-model="form.userAccount" placeholder="请输入用户名" />
      </a-form-item>
      <a-form-item field="post" label="密码">
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item field="post" label="确认密码">
        <a-input-password
          v-model="form.checkPassword"
          placeholder="请再次输入密码"
        />
      </a-form-item>
      <a-form-item>
        <a-button html-type="submit" long status="success">注册</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style scoped>
#user-login h2 {
  text-align: center;
}
</style>

<script setup lang="ts">
import { reactive } from "vue";
import { UserControllerService, UserRegisterRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const form = reactive({
  userAccount: "",
  userPassword: "",
  checkPassword: "",
}) as UserRegisterRequest;

const store = useStore();
const router = useRouter();
/**
 * 提交登录表单
 */
const loginSubmit = async () => {
  const res = await UserControllerService.userRegisterUsingPost(form);
  if (res.code === 0) {
    router.push({
      path: "/user/login",
      replace: true,
    });
    message.success("注册成功" + res.message);
  } else {
    message.error("注册失败" + res.message);
  }
};
</script>
