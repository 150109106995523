<template>
  <Viewer :value="value" :plugins="plugins" />
</template>

<script setup lang="ts">
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { Viewer } from "@bytemd/vue-next";
import "highlight.js/styles/gradient-light.css"; // 代码高亮的主题样式(可自选)
import { defineProps, withDefaults } from "vue";

/**
 * 定义组件类型属性
 */
interface Props {
  value: string;
}

const plugins = [gfm(), highlight()];
const props = withDefaults(defineProps<Props>(), {
  value: () => "",
});
</script>

<style scoped></style>
