<template>
  <div id="viewQuestionView">
    <a-row :gutter="20">
      <a-col :md="10" :xs="24">
        <a-list max-height="75vh">
          <a-card
            v-if="questionSubmit"
            :title="questionSubmit.questionVO?.title"
          >
            <a-descriptions title="判题条件" :column="{ xs: 1, md: 2, lg: 3 }">
              <a-descriptions-item label="时间限制">
                {{ questionSubmit.questionVO?.judgeConfig.timeLimit ?? 1000 }}ms
              </a-descriptions-item>
              <a-descriptions-item label="内存限制">
                {{
                  questionSubmit.questionVO?.judgeConfig.memoryLimit ?? 1000
                }}KB
              </a-descriptions-item>
              <a-descriptions-item label="堆栈限制">
                {{
                  questionSubmit.questionVO?.judgeConfig.stackLimit ?? 1000
                }}KB
              </a-descriptions-item>
            </a-descriptions>
            <a-divider />
            <MDViewer :value="questionSubmit.questionVO?.content || ''" />
            <template #extra>
              <a-space wrap>
                <a-tag
                  v-for="(item, index) of questionSubmit.questionVO?.tags"
                  :key="index"
                  color="green"
                >
                  {{ item }}
                </a-tag>
              </a-space>
            </template>
            <a-divider />
            <a-descriptions title="用户使用" :column="{ xs: 1, md: 2, lg: 3 }">
              <a-descriptions-item label="时间">
                {{ questionSubmit.judgeInfo.time }}ms
              </a-descriptions-item>
              <a-descriptions-item label="内存">
                {{ questionSubmit.judgeInfo.memory / 1024 }}KB
              </a-descriptions-item>
              <a-descriptions-item label="结果">
                <a-tag :color="getColor()">
                  {{ questionSubmit.judgeInfo?.message }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </a-list>
      </a-col>
      <a-col :md="14">
        <a-form :model="form" layout="inline">
          <a-form-item field="language" label="语言" style="min-width: 250px">
            <a-select
              disabled
              v-model="form.language"
              placeholder="请选择编程语言"
            >
              <a-option>{{ form.language }}</a-option>
            </a-select>
          </a-form-item>
        </a-form>
        <CodeEditor
          v-if="form.code !== ''"
          :language="form.language"
          :value="form.code"
          :readonly="true"
          class="code-editor"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, withDefaults } from "vue";
import {
  QuestionSubmitAddRequest,
  QuestionSubmitControllerService,
  QuestionSubmitVO,
} from "../../../generated";
import CodeEditor from "@/components/CodeEditor.vue";
import MDViewer from "@/components/MDViewer.vue";
import message from "@arco-design/web-vue/es/message";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => 0,
});
const questionSubmit = ref<QuestionSubmitVO>();

//颜色对应
const messageColor: Map<string, string> = new Map([
  ["Accepted", "#00b42a"],
  ["Waiting", "#ff7d00"],
  ["WRONG_ANSWER", "#f53f3f"],
]);
//可选编程语言
const form = ref<QuestionSubmitAddRequest>({
  language: "java",
  code: "",
  questionId: props.id,
});
//获取题目信息
const loadData = async () => {
  const res = await QuestionSubmitControllerService.getQuestionByIdUsingGet(
    props.id
  );
  if (res.code === 0) {
    //
    questionSubmit.value = res.data;
    form.value.language = res.data?.language;
    form.value.code = res.data?.code;
  } else {
    message.error("加载失败：" + res.message);
  }
};
//页面加载时出发
onMounted(() => {
  loadData();
});
//获取颜色
const getColor = () => {
  let color = messageColor.get(
    questionSubmit.value?.judgeInfo?.message as string
  );
  if (!color) {
    color = "#f53f3f";
  }
  return color;
};
</script>
<style scoped>
#viewQuestionView {
  padding: 0 20px;
}
.code-editor {
  min-width: 800px;
  min-height: 70vh;
}

.submitCode {
  margin-top: 20px;
  min-width: 150px;
}
</style>
