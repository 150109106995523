import { RouteRecordRaw } from "vue-router";
import AUTH_ENUM from "@/auth/AUTH_ENUM";
import UserLayout from "@/layouts/UserLayout.vue";
import UserLoginView from "@/views/user/UserLoginView.vue";
import UserRegisterView from "@/views/user/UserRegisterView.vue";
import AddQuestionView from "@/views/question/AddQuestionView.vue";
import ManageQuestionView from "@/views/question/ManageQuestionView.vue";
import UpdateQuestionView from "@/views/question/UpdateQuestionView.vue";
import QuestionsView from "@/views/question/QuestionsView.vue";
import ViewQuestionView from "@/views/question/ViewQuestionView.vue";
import SubmitQuestionsView from "@/views/submitquestion/SubmitQuestionsView.vue";
import ViewSubmitQuestionView from "@/views/submitquestion/ViewSubmitQuestionView.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "用户",
    component: UserLayout,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: "login",
        name: "用户登录",
        component: UserLoginView,
      },
      {
        path: "register",
        name: "用户注册",
        component: UserRegisterView,
      },
    ],
  },
  {
    path: "/",
    name: "主页",
    redirect: "/questions",
    meta: {
      hidden: true,
    },
  },
  {
    path: "/questions",
    name: "浏览题目",
    meta: {
      authorize: AUTH_ENUM.USER,
    },
    component: QuestionsView,
  },
  {
    path: "/submit-question",
    name: "提交列表",
    meta: {
      authorize: AUTH_ENUM.USER,
    },
    component: SubmitQuestionsView,
  },
  {
    path: "/view/submit-question/:id",
    name: "浏览提交题目信息",
    props: true,
    meta: {
      authorize: AUTH_ENUM.USER,
      hidden: true,
    },
    component: ViewSubmitQuestionView,
  },
  {
    path: "/add/question",
    name: "创建题目",
    meta: {
      authorize: AUTH_ENUM.ADMIN,
    },
    component: AddQuestionView,
  },
  {
    path: "/update/question",
    name: "修改题目",
    meta: {
      authorize: AUTH_ENUM.ADMIN,
      hidden: true,
    },
    component: UpdateQuestionView,
  },
  {
    path: "/manage/question",
    name: "管理题目",
    meta: {
      authorize: AUTH_ENUM.ADMIN,
    },
    component: ManageQuestionView,
  },
  {
    path: "/view/question/:id",
    name: "在线做题",
    props: true,
    meta: {
      authorize: AUTH_ENUM.USER,
      hidden: true,
    },
    component: ViewQuestionView,
  },
];
