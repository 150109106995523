<template>
  <div id="code-editor" ref="codeEditorRef" style="height: 400px" />
</template>
<script setup lang="ts">
import * as monaco from "monaco-editor";
import { defineProps, onMounted, ref, toRaw, watch, withDefaults } from "vue";

interface Props {
  value: string;
  handleChange: (v: string) => void;
  language?: string;
  readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: () => "",
  handleChange: (v: string) => {
    console.log(v);
  },
  language: () => "java",
  readonly: () => false,
});

const codeEditorRef = ref();
const codeEditor = ref();
const value = ref();

//监听变量发生变化
watch(
  () => props.language,
  () => {
    if (codeEditor.value) {
      monaco.editor.setModelLanguage(
        toRaw(codeEditor.value).getModel(),
        props.language
      );
    }
  }
);

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }
  console.log("默认值", props.value);
  codeEditor.value = monaco.editor.create(codeEditorRef.value, {
    value: props.value,
    language: props.language,
    automaticLayout: true,
    minimap: {
      enabled: true,
    },
    colorDecorators: true,
    readOnly: props.readonly,
    theme: "vs-dark",
  });
  //监听编辑器的变化
  codeEditor.value.onDidChangeModelContent(() => {
    props.handleChange(toRaw(codeEditor.value).getValue());
  });
});
</script>

<style scoped></style>
