<template>
  <div id="updateQuestionView">
    <a-form
      :model="form"
      auto-label-width
      style="width: 1000px"
      aria-label="left"
      class="add-form"
    >
      <a-divider orientation="center">创建题目</a-divider>
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tags" placeholder="请输入标签" allow-clear />
      </a-form-item>
      <a-form-item field="content" label="题目内容">
        <MDEditor
          :value="form.content"
          :handle-change="handleChangeContent"
          class="md-editor"
        />
      </a-form-item>
      <a-form-item field="answer" label="答案">
        <MDEditor
          :value="form.answer"
          :handle-change="handleChangeAnswer"
          class="md-editor"
        />
      </a-form-item>

      <a-form-item label="判题配置" :content-flex="true" :merge-props="false">
        <a-space direction="vertical" fill>
          <a-form-item field="judgeConfig.timeLimit" label="时间限制">
            <a-input-number
              v-model="form.judgeConfig.timeLimit"
              mode="button"
              size="large"
              min="0"
              placeholder="请输入时间限制"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.memoryLimit" label="内存限制">
            <a-input-number
              v-model="form.judgeConfig.memoryLimit"
              mode="button"
              size="large"
              min="0"
              placeholder="请输入内存限制"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.stackLimit" label="堆栈限制">
            <a-input-number
              v-model="form.judgeConfig.stackLimit"
              mode="button"
              size="large"
              min="0"
              placeholder="请输入堆栈限制"
            />
          </a-form-item>
        </a-space>
      </a-form-item>

      <a-divider orientation="center">测试用例配置</a-divider>
      <a-form-item
        v-for="(caseItem, index) of form.judgeCase"
        :field="`form.judgeCase.[${index}].input`"
        :label="`测试用例-${index}`"
        :key="index"
      >
        <a-row gutter="10">
          <a-col span="10">
            <a-form-item
              :field="`form.judgeCase.[${index}].input`"
              :label="`输入用例-${index}`"
              :key="index"
            >
              <a-input v-model="caseItem.input" placeholder="请输入输入用例" />
            </a-form-item>
          </a-col>
          <a-col span="10">
            <a-form-item
              :field="`form.judgeCase.[${index}].output`"
              :label="`输出用例-${index}`"
              :key="index"
            >
              <a-input v-model="caseItem.output" placeholder="请输入输出用例" />
            </a-form-item>
          </a-col>
          <a-col span="4">
            <a-button
              @click="handleDelete(index)"
              status="danger"
              :style="{ marginLeft: '10px' }"
            >
              删除
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item class="button-box">
        <a-button
          @click="handleAdd"
          type="outline"
          style="max-width: 480px"
          status="success"
        >
          新增测试用例
        </a-button>
      </a-form-item>

      <a-divider />
      <a-form-item class="button-box">
        <a-button
          type="primary"
          @click="addQuestion"
          long
          style="max-width: 480px"
        >
          提交题目
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import MDEditor from "@/components/MDEditor.vue";
import { QuestionControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";

const router = useRouter();
const form = reactive({
  answer: "",
  content: "",
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
  judgeConfig: {
    memoryLimit: 1240 * 1240,
    stackLimit: 10000,
    timeLimit: 10000,
  },
  tags: [],
  title: "",
});
//删除用例
const handleDelete = (index: number) => {
  form.judgeCase.splice(index, 1);
};
//新增用例
const handleAdd = () => {
  form.judgeCase.push({
    input: "",
    output: "",
  });
};
//监听富文本框变化
const handleChangeContent = (v: string) => {
  form.content = v;
};
const handleChangeAnswer = (v: string) => {
  form.answer = v;
};
//提交题目
const addQuestion = async () => {
  const res = await QuestionControllerService.addQuestionUsingPost(form);
  if (res.code === 0) {
    message.success("创建成功");
    router.push("/");
  } else {
    message.error("创建失败");
  }
};
</script>

<style scoped>
#updateQuestionView {
  display: flex;
  justify-content: center;
}

.md-editor {
  width: 100%;
  margin: 0 auto;
}
</style>
