import { StoreOptions } from "vuex";
import AUTH_ENUM from "@/auth/AUTH_ENUM";
import { UserControllerService } from "../../generated";

export default {
  namespaced: true,
  state: () => ({
    loginUser: {
      userName: "未登录",
    },
  }),
  getters: {},
  actions: {
    async getLoginUser({ commit, state }, payload) {
      const res = await UserControllerService.getLoginUserUsingGet();
      if (res.code === 0) {
        commit("updateUser", res.data);
      } else {
        commit("updateUser", { ...state.loginUser, role: AUTH_ENUM.NOT_LOGIN });
      }
    },
  },
  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload;
      state.loginUser.role = payload.userRole;
    },
  },
} as StoreOptions<any>;
