import AUTH_ENUM from "@/auth/AUTH_ENUM";

/**
 * 判断当前登录用户是否具有某种权限
 * @param loginUser 登录用户
 * @param needAuth  所需权限
 * @return boolean  返回是否具有权限
 */
const checkAuthorize = (loginUser: any, needAuth = AUTH_ENUM.NOT_LOGIN) => {
  const authorize = loginUser?.role ?? AUTH_ENUM.NOT_LOGIN;
  if (needAuth === AUTH_ENUM.NOT_LOGIN) {
    return true;
  }
  if (needAuth === AUTH_ENUM.USER) {
    if (authorize === AUTH_ENUM.NOT_LOGIN) {
      console.log("未登录过滤");
      return false;
    }
  }
  if (needAuth === AUTH_ENUM.ADMIN) {
    if (authorize !== AUTH_ENUM.ADMIN) {
      return false;
    }
  }
  return true;
};
export default checkAuthorize;
