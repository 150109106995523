<template>
  <div id="manageQuestionView">
    <a-table
      column-resizable
      :data="dataList"
      :pagination="{
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total: searchParams.total,
      }"
      @page-change="pageChange"
    >
      <template #columns>
        <a-table-column title="id" data-index="id"></a-table-column>
        <a-table-column title="题目" data-index="title"></a-table-column>
        <a-table-column title="标签" data-index="tags">
          <template #cell="{ record }">
            <a-space>
              <a-tag
                v-for="(item, index) of JSON.parse(record.tags)"
                :key="index"
                color="green"
              >
                {{ item }}
              </a-tag>
            </a-space>
          </template>
        </a-table-column>
        <a-table-column title="提交数" data-index="submitNum"></a-table-column>
        <a-table-column
          title="通过数"
          data-index="acceptedNum"
        ></a-table-column>
        <a-table-column
          title="创建时间"
          data-index="createTime"
        ></a-table-column>
        <a-table-column title="操作" data-index="Optional">
          <template #cell="{ record }">
            <a-space>
              <a-button @click="handleUpdadeData(record)" type="outline">
                修改
              </a-button>
              <a-button @click="handleDeleteData(record)" status="danger">
                删除
              </a-button>
            </a-space>
          </template>
        </a-table-column>
      </template>
    </a-table>
    <a-modal width="auto" v-model:visible="show" @ok="false" @cancel="false">
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import { Question, QuestionControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { Message, Modal } from "@arco-design/web-vue";
import { useRouter } from "vue-router";

const show = ref(false);
const dataList = ref([]);
const router = useRouter();

let searchParams = ref({
  showTotal: true,
  pageSize: 10,
  current: 1,
  total: 0,
});

/**
 * 获取数据方法
 */
const loadData = async () => {
  let res = await QuestionControllerService.listQuestionByPageUsingPost(
    searchParams.value
  );
  if (res.code == 0) {
    dataList.value = res.data.records;
    searchParams.value.total = Number.parseInt(res.data.total);
  } else {
    message.error("加载失败：", res.message);
  }
};
//页面初始化时获取数据
onMounted(() => {
  loadData();
});
//点击打开修改页面
const handleUpdadeData = (data: Question) => {
  router.push({
    path: "/update/question",
    query: {
      id: data.id,
    },
  });
};

//点击删除按钮
const handleDeleteData = (data: Question) => {
  Modal.error({
    title: "删除",
    hideCancel: false,
    content: "确定要删除吗？删除后将不可恢复！！！",
    okText: "确定",
    cancelText: "取消",
    onOk: async () => {
      const res = await QuestionControllerService.deleteQuestionUsingPost({
        id: data.id,
      });
      if (res.code == 0) {
        Message.success("删除成功");
        loadData();
      } else {
        Message.error("删除失败:", res.message);
      }
    },
    onCancel: () => {
      Message.success("取消成功");
    },
  });
};
//分页改变触发
const pageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  } as any;
  // loadData();
};
watchEffect(() => {
  loadData();
});
</script>

<style scoped>
#manageQuestionView {
  padding: 0 50px;
}
</style>
