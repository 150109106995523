<template>
  <div id="user_layout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <div class="back-click" @click="router.back()">
          <img alt="logo" src="../assets/moj-logo.svg" class="logo" />
          <span class="title">&nbsp;&nbsp;MOJ</span>
        </div>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        <p>
          青年强则国家强。当代中国青年生逢其时，施展才干的舞台无比广阔，实现梦想的前景无比光明。
        </p>
        <p>
          要坚定不移听党话、跟党走，怀抱梦想又脚踏实地，敢想敢为又善作善成，立志做有理想、敢担当、能吃苦、肯奋斗的新时代好青年,
        </p>
        <p>让青春在全面建设社会主义现代化国家的火热实践中绽放绚丽之花。</p>
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<style scoped>
#user_layout {
  background: url("../assets/background.png");
}

#user_layout .header {
  padding: 10px 20px;
  box-shadow: #eee 1px 1px 5px;
  font-size: 18px;
}

#user_layout .header .back-click {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

#user_layout .header .logo {
  border-left: 20px;
  height: 30px;
}

#user_layout .content {
  padding: 20px;
  margin: 0 auto;
}

#user_layout .footer {
  padding: 16px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font: normal 20px 华文行楷;
}
</style>
<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();
</script>
