<template>
  <div id="questions">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="questionId" label="题号" style="min-width: 250px">
        <a-input v-model="searchParams.questionId" placeholder="请输入题号" />
      </a-form-item>
      <a-form-item field="language" label="语言" style="min-width: 250px">
        <a-select v-model="searchParams.language" placeholder="请选择变成语言">
          <a-option v-for="(language, item) of languages" :key="item">
            {{ language }}
          </a-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-space>
          <a-button type="primary" @click="search">搜索</a-button>
          <a-button type="outline" status="warning" @click="clearSearch">
            取消
          </a-button>
        </a-space>
      </a-form-item>
    </a-form>
    <a-table
      column-resizable
      :data="dataList"
      :pagination="{
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total: searchParams.total,
      }"
      @page-change="pageChange"
    >
      <template #columns>
        <a-table-column title="题号" data-index="id">
          <template #cell="{ record }">
            <a-popover position="right">
              <a-button type="text" @click="toQuestionPage(record.questionId)">
                {{ record.questionId }}
              </a-button>
              <template #content>
                <p>{{ record?.questionVO?.title ?? "无名" }}</p>
              </template>
            </a-popover>
          </template>
        </a-table-column>
        <a-table-column title="用户" data-index="userVO.userName" />
        <a-table-column title="语言" data-index="language" />
        <a-table-column title="状态" data-index="status">
          <template #cell="{ record }">
            <a-tag v-if="record.status === 0" color="#ff5722">等待中</a-tag>
            <a-tag v-if="record.status === 1" color="#168cff">判题中</a-tag>
            <a-tag v-if="record.status === 2" color="#00b42a">成功</a-tag>
            <a-tag v-if="record.status === 3" color="#f53f3f">失败</a-tag>
          </template>
        </a-table-column>
        <a-table-column title="提交结果" data-index="message">
          <template #cell="{ record }">
            <a-tag
              v-if="record.judgeInfo.message === 'Accepted'"
              color="#00b42a"
            >
              {{ record.judgeInfo.message }}
            </a-tag>
            <a-tag
              v-else-if="record.judgeInfo.message === 'Memory Limit Exceeded'"
              color="#168cff"
            >
              {{ record.judgeInfo.message }}
            </a-tag>
            <a-tag
              v-else-if="record.judgeInfo.message === 'Time Limit Exceeded'"
              color="#00b42a"
            >
              {{ record.judgeInfo.message }}
            </a-tag>
            <a-tag v-else-if="!record.judgeInfo.message" color="#ff7d00"
              >Waiting
            </a-tag>
            <a-tag v-else color="#f53f3f">{{ record.judgeInfo.message }}</a-tag>
          </template>
        </a-table-column>
        <a-table-column title="内存占用（B）" data-index="judgeInfo.memory" />
        <a-table-column title="用时(ms)" data-index="judgeInfo.time" />
        <a-table-column title="提交时间" data-index="createTime" />
        <a-table-column v-if="userId" title="操作" data-index="optional">
          <template #cell="{ record }">
            <a-button
              type="dashed"
              status="success"
              @click="doViewSubmitQuestion(record.id)"
            >
              查看
            </a-button>
          </template>
        </a-table-column>
      </template>
    </a-table>
    <a-modal width="auto" v-model:visible="show" @ok="false" @cancel="false">
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import { QuestionSubmitControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRoute, useRouter } from "vue-router";
import { languages } from "@/language/language";

const show = ref(false);
const dataList = ref([]);
const router = useRouter();
const route = useRoute();

let userId = "";
//搜索参数
let searchParams = ref({
  showTotal: true,
  pageSize: 10,
  current: 1,
  total: 0,
  userId: "",
  questionId: "",
  language: "",
  sortField: "createTime",
  sortOrder: "descend",
});
/**
 * 获取数据方法
 */
const loadData = async () => {
  userId = route.query.userId as string;
  searchParams.value.userId = userId;
  let res =
    await QuestionSubmitControllerService.listQuestionSubmitVoByPageUsingPost(
      searchParams.value
    );
  if (res.code == 0) {
    dataList.value = res.data.records;
    searchParams.value.total = Number.parseInt(res.data.total);
  } else {
    message.error("加载失败：", res.message);
  }
};
//页面初始化时获取数据
onMounted(() => {
  loadData();
});
//点击进入做题页面
const toQuestionPage = (questionId: number) => {
  router.push({
    path: `/view/question/${questionId}`,
  });
};

//分页改变触发
const pageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  } as any;
  loadData();
};
watchEffect(() => {
  loadData();
});
//搜索题目
const search = () => {
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};
//清空搜索条件
const clearSearch = () => {
  searchParams.value = {
    ...searchParams.value,
    current: 1,
    language: "",
    questionId: "",
  };
};
//点击前往查看做题详情页面
const doViewSubmitQuestion = (submitQuestionId: string) => {
  router.push({
    path: `/view/submit-question/${submitQuestionId}`,
  });
};
</script>

<style scoped>
#questions {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
