import router from "@/router";
import store from "@/store";
import AUTH_ENUM from "@/auth/AUTH_ENUM";
import checkAuthorize from "@/auth/CheckAuthorize";

router.beforeEach(async (to, form, next) => {
  const needAuth = to.meta?.authorize ?? AUTH_ENUM.NOT_LOGIN;
  let loginUser = store.state.user.loginUser;
  //已登录，直接进行登录
  if (!loginUser || !loginUser.role) {
    await store.dispatch("user/getLoginUser");
    loginUser = store.state.user.loginUser;
  }
  //需要登录
  if (needAuth !== AUTH_ENUM.NOT_LOGIN) {
    if (!loginUser || !loginUser.role) {
      next(`/user/login?redirect=${to.fullPath}`);
      return;
    }
    if (!checkAuthorize(loginUser, needAuth as string)) {
      next("notAUth");
      return;
    }
  }
  //用户跳转的页面需要登录
  if (needAuth) next();
});
