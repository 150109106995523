<template>
  <a-row class="navBar" align="center" :wrap="false">
    <a-col flex="auto">
      <div>
        <a-menu
          mode="horizontal"
          :selected-keys="selectedKeys"
          @menu-item-click="doClick"
        >
          <a-menu-item
            key="0"
            :style="{ padding: 0, marginRight: '38px' }"
            @click="goHome"
          >
            <div class="title-bar">
              <img class="logo" src="../assets/moj-logo.svg" />
              <span class="title">MOJ</span>
            </div>
          </a-menu-item>

          <a-menu-item v-for="item in navBars" :key="item.path">
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </div>
    </a-col>
    <a-col flex="100px">
      <a-dropdown trigger="hover">
        <a-button type="text">{{ loginUserName }}</a-button>
        <template #content>
          <a-doption
            v-if="loginUserName !== '未登录'"
            @click="goSubmitQuestion"
          >
            我的提交
          </a-doption>
          <a-doption v-if="loginUserName === '未登录'" @click="goLogin"
            >去登录
          </a-doption>
          <a-doption v-else @click="logout">退出登录</a-doption>
        </template>
      </a-dropdown>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "@/router/routers";
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAuthorize from "@/auth/CheckAuthorize";
import { UserControllerService } from "../../generated";
import message from "@arco-design/web-vue/es/message";

const router = useRouter();
const route = useRoute();

// 设置默认选中菜单
const selectedKeys = ref(["/"]);
// 路由跳转时更新
router.afterEach((to, from, next) => {
  selectedKeys.value = [to.path];
});

// 点击导航页面进行跳转
const doClick = (key: string) => {
  router.push({ path: key });
};
const store = useStore();
const userId = computed(() => {
  return store.state.user?.loginUser?.id;
});
//获取当前登录用户
const loginUserName = computed(() => {
  return store.state.user?.loginUser?.userName ?? "未登录";
});
//过滤隐藏菜单
const navBars = computed(() => {
  return routes.filter((item, index) => {
    if (item.meta?.hidden === true) {
      return false;
    }
    const loginUser = store.state.user.loginUser;
    if (!checkAuthorize(loginUser, item.meta?.authorize as string)) {
      return false;
    }
    return true;
  });
});

//退出登录
const logout = async () => {
  store.commit("user/updateUser", { userRole: "" });
  //调用对出登录接口
  const res = await UserControllerService.userLogoutUsingPost();
  if (res.code == 0) {
    message.success("退出成功！");
    router.push("/user/login");
  } else {
    message.error("退出登录失败，" + res.message);
  }
};
//前往登录
const goLogin = () => {
  router.push("/user/login");
};
//前往我的提交页面
const goSubmitQuestion = () => {
  router.push({
    path: "/submit-question",
    query: {
      userId: userId.value,
    },
  });
};
//点击logo前往首页
const goHome = () => {
  router.push({
    path: "/",
  });
};
</script>

<style scoped>
.title-bar {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.title-bar .logo {
  height: 30px;
}

.title-bar .title {
  color: #444;
  font-size: 18px;
  margin-left: 10px;
}
</style>
