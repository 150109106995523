<template>
  <div id="questions">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="title" label="题目" style="min-width: 250px">
        <a-input v-model="searchParams.title" placeholder="请输入题目名称" />
      </a-form-item>
      <a-form-item field="tags" label="tags" style="min-width: 250px">
        <a-input-tag
          v-model="searchParams.tags"
          placeholder="请输入要搜索的标签"
        />
      </a-form-item>
      <a-form-item>
        <a-space>
          <a-button type="primary" @click="search">搜索</a-button>
          <a-button type="outline" status="warning" @click="clearSearch">
            取消
          </a-button>
        </a-space>
      </a-form-item>
    </a-form>
    <a-table
      column-resizable
      :data="dataList"
      :pagination="{
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total: searchParams.total,
      }"
      @page-change="pageChange"
    >
      <template #columns>
        <a-table-column title="题号" data-index="id"></a-table-column>
        <a-table-column title="题目" data-index="title"></a-table-column>
        <a-table-column title="标签" data-index="tags">
          <template #cell="{ record }">
            <a-space>
              <a-tag
                v-for="(item, index) of record.tags"
                :key="index"
                color="green"
              >
                {{ item }}
              </a-tag>
            </a-space>
          </template>
        </a-table-column>
        <a-table-column title="通过率(通过数/提交数)" data-index="submitNum">
          <template #cell="{ record }">
            {{
              (record.submitNum !== 0
                ? record.acceptedNum / record.submitNum
                : 0
              ).toFixed(2) *
                100 +
              "%\t\t(\t" +
              record.acceptedNum +
              "\t/\t" +
              record.submitNum +
              "\t)"
            }}
          </template>
        </a-table-column>
        <a-table-column
          title="创建时间"
          data-index="createTime"
        ></a-table-column>
        <a-table-column title="操作" data-index="Optional">
          <template #cell="{ record }">
            <a-space>
              <a-button
                @click="toQuestionPage(record)"
                status="success"
                type="outline"
              >
                前往做题
              </a-button>
            </a-space>
          </template>
        </a-table-column>
      </template>
    </a-table>
    <a-modal width="auto" v-model:visible="show" @ok="false" @cancel="false">
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import { QuestionControllerService, QuestionVO } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";

const show = ref(false);
const dataList = ref([]);
const router = useRouter();

let searchParams = ref({
  showTotal: true,
  pageSize: 10,
  current: 1,
  total: 0,
  title: "",
  tags: [],
});
/**
 * 获取数据方法
 */
const loadData = async () => {
  let res = await QuestionControllerService.listQuestionVoByPageUsingPost(
    searchParams.value
  );
  if (res.code == 0) {
    dataList.value = res.data.records;
    searchParams.value.total = Number.parseInt(res.data.total);
  } else {
    message.error("加载失败：", res.message);
  }
};
//页面初始化时获取数据
onMounted(() => {
  loadData();
});
//点击进入做题页面
const toQuestionPage = (question: QuestionVO) => {
  router.push({
    path: `/view/question/${question.id}`,
  });
};

//分页改变触发
const pageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  } as any;
  // loadData();
};
watchEffect(() => {
  loadData();
});
//搜索题目
const search = () => {
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};
//清空搜索条件
const clearSearch = () => {
  searchParams.value = {
    ...searchParams.value,
    current: 1,
    title: "",
    tags: [],
  };
};
</script>

<style scoped>
#questions {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
